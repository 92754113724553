<template>
  <div class="page">
    <Viewheader theme="light" :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <img class="banner-bg" :src="bannerImg" />
        <img class="banner-auto" src="@/assets/img/logoSmear/banner-2.png" />
        <img class="banner-custom" src="@/assets/img/logoSmear/banner-3.png" />
      </div>
    </BannerHead>

    <!-- 等比例放大比图片 -->
    <canvas ref="canvas" id="canvas1" style="display: none"></canvas>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo
        class="core-function_main"
        :list="spanList"
        :img="coreImg"
      ></ColumuTwo>
      <div class="core-list">
        <img src="@/assets/img/logoSmear/core-1.png" />
        <img src="@/assets/img/logoSmear/core-2.png" />
        <img src="@/assets/img/logoSmear/core-3.png" />
      </div>
    </div>

    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" :desc="ability.desc" />
        <div class="operationWidth similar-main-cont">
          <ImgUpload
            class="sc-img-upload"
            @success="getResultImg"
            @select="selectPicture"
            @change="handleChange"
            :modelIndex.sync="imgCurrentActive"
            :imgList="exampleDiagram"
            :action="action"
            :directionRow1280="true"
            accept="image/jpeg,image/jpg,image/png"
            uploadText="选择一张"
          />
          <div class="logoImg hover-container">
            <canvas id="canvas" class="img-thumbnail"></canvas>

            <div class="logoImgLoad" ref="imageWrapper">
              <!-- 结果图 -->

              <img
                :src="resultGraphImg"
                v-if="successImg"
                class="logoImgResult"
                ref="image"
                @load="getScaledSize"
              />
              <!-- 加载特效 -->
              <div class="loadSpecialEffects" v-if="loadImg"></div>
              <!-- 扫描脚框 -->
              <div class="top" v-if="loadImg"></div>
              <div class="bottom" v-if="loadImg"></div>

              <!-- 报错图片 -->
              <div class="errMain" v-if="errImg">
                <div class="errImg">
                  <img src="../../assets/img/err.png" />
                </div>
                <div class="errText">
                  {{ errText }}
                </div>
              </div>
            </div>
          </div>
          <div class="paintingBrush">
            <!-- <div
              class="penClear"
              id="clean"
              :class="{ disable: cleanImgList.length == 1 }"
            >
              <img src="../../assets/img/logoSmear/ability-2.png" />撤销
            </div> -->

            <div class="flex" style="column-gap: 16px">
              <div
                class="penClear"
                id="clean"
                :class="{ disable: cleanImgList.length == 1 }"
              >
                <img
                  type="disabled"
                  src="../../assets/img/1024/smear_withdraw.png"
                />
                <img
                  type="normal"
                  src="../../assets/img/1024/smear_withdraw_hover.png"
                />
                <!-- src="../../assets/img/1024/smear_withdraw_normal.png" -->
                <img
                  type="hover"
                  src="../../assets/img/1024/smear_withdraw_hover.png"
                />
                <img
                  type="active"
                  src="../../assets/img/1024/smear_withdraw_active.png"
                />
              </div>
              <div
                class="penClear"
                :class="{ disable: !this.imageName }"
                @click="submitForm()"
              >
                <img
                  type="disabled"
                  src="../../assets/img/1024/smear_download.png"
                />
                <img
                  type="normal"
                  src="../../assets/img/1024/smear_download_hover.png"
                />
                <!-- src="../../assets/img/1024/smear_download_normal.png" -->
                <img
                  type="hover"
                  src="../../assets/img/1024/smear_download_hover.png"
                />
                <img
                  type="active"
                  src="../../assets/img/1024/smear_download_active.png"
                />
              </div>
            </div>

            <div class="paintingBrushSize">
              <div class="penSizePhone">
                <img
                  src="../../assets/img/logoSmear/ability-1.png"
                  class="penSize"
                />
                画笔大小
              </div>
              <div class="range">
                <input
                  ref="inputRange"
                  type="range"
                  min="10"
                  max="70"
                  step="1"
                  class="custom-range"
                  id="customRange1"
                  style="width: 175px"
                  v-model="rangValue"
                  @input="handleInput"
                />
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>

    <!-- 差异优势 -->
    <div class="different-advantage-wrap">
      <div class="different-advantage">
        <CommonTitle title="差异优势" theme="light" />
        <ColumuTwo
          class="different-advantage_main"
          theme="light"
          :list="diffList"
          :img="diffImg"
        ></ColumuTwo>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo
        class="application-scene_main"
        :list="appliList"
        :img="appImg"
      ></ColumuTwo>
    </div>
    <!-- // 鼠标滑过事件 变换成圆圈 -->
    <div id="custom-cursor">
      <div id="move"></div>
    </div>
    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';
// 上传图片列表组件
import ImgUpload from '@/components/secondPage/imgUpload.vue';

import googleLogo from '@/assets/img/imgSimilar/google.png';

// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '@/components/foot/footer.vue';
import { apiUri } from '../../api/txt';
import { logoSmear } from '@/api/aidata.js';

// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/commonModule.css';

// canvas画布
// import "../../api/canvas/jquery.min.js"
// import "../../api/canvas/canvas.js"
import '../../api/canvas/jquery.min.js';
import '../../api/canvas/popper.min.js';
export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    ImgUpload,
    Viewheader,
    Footering,
  },
  data() {
    return {
      bannerImg: require('@/assets/img/logoSmear/banner-1.png'),
      coreImg: require('@/assets/img/logoSmear/core-4.png'),
      bigTitle_1: '<span style="color:#fff;">图片元素润饰</span>',
      smallTitle_1:
        '<span style="color:#fff;">检测图片中特定元素，并进行自动化润饰。且支撑自定义元素区域。</span>',

      // 核心功能
      spanList: [
        {
          title: '特定元素检测',
          desc: '自动化检测特定元素坐标位置，自动对选定区域进行润饰；',
        },
      ],

      //   差异优势
      diffImg: require('@/assets/img/logoSmear/diff-1.png'),
      diffList: [
        {
          title: '支持接口批量化处理',
          desc: '特定元素自动检测、元素自动润饰；',
        },
      ],

      //   应用场景
      appImg: require('@/assets/img/logoSmear/app-1.png'),
      appliList: [
        {
          title: '电商平台/店铺安全',
          desc: '自动识别或手动商品品牌、水印、无关背景等元素检测，并进行涂抹。实现平台商品准入及风险管控。',
        },
        {
          title: '媒体投放合规',
          desc: '规模化社媒投放中，提前对投放图进行处理，提升素材处理效率。',
        },
      ],
      // 能力展示
      ability: {
        desc: `模型结果对<img src='${googleLogo}' /> <span style='color:#6C38E0'>google 系列产品（shopping/⾕歌seo等）</span>，进⾏了针对性优化，在此场景下使⽤效果更优。模型⽬前仅⽀持png、jpg、jpeg。`,
      },
      // 示例图列表
      exampleDiagram: [
        // {
        //   img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/tumo_1JB_Gn8c617.jpg'
        // }, {
        //   img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/img_v2_34344095-8cbb-4da3-8ad8-83469ea60f4g.jpg'
        // }, {
        //   img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/img_v2_f0e95bbe-408e-407e-954e-2453a936e55g.jpg'
        // }, {
        //   img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/img_v2_4794a651-2a4b-46a9-bc2c-eee29e813c0g.jpg'
        // }, {
        //   img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/img_v2_7519f7f2-6644-4266-8eb4-ebe439ea769g.jpg'
        // }
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl-1.jpeg',
        },
        // {
        //   img: "https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl-3.jpg"
        // },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl2.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl4.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl5.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl6.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl7.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl8.jpg',
        },
      ],
      // 初始化选中状态
      imgCurrentActive: 0,
      // 初始化选中图
      resultGraphImg:
        'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl-1.jpeg',
      sendisabled: true,
      action: `${apiUri}/img/imgUpload`,
      limit: Number(1),
      multiple: true,
      fileList: [],
      // 加载时，禁止点击
      prohibitClicking: false,
      logoExample: true,

      errText: '',
      // 成功图
      successImg: true,
      // 失败图
      errImg: false,
      // 正在加载
      loadImg: false,
      // 加载文案
      errText: '处理中....',
      widtha: '',
      heighta: '',
      imageWidth: '',
      imageHeight: '',
      imageName: '',
      cleanImgList: [],
      rangValue: 40,
      isSample: '',
    };
  },
  watch: {
    rangValue: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          const rate = parseInt(((val - 10) / 60) * 100) + '%';
          this.$refs.inputRange.style.backgroundSize = `${rate} 100%`;
        });
      },
    },
  },

  computed: {},
  mounted() {
    // 默认点击高亮
    this.selectPicture(this.imgCurrentActive, { img: this.resultGraphImg });

    // 鼠标滑过事件 变换成圆圈
    const customCursor = document.getElementById('custom-cursor');
    const hoverContainer = document.querySelector('.hover-container');
    const updateCursorPosition = (event) => {
      customCursor.style.top = `${event.clientY - this.rangValue / 2}px`;
      customCursor.style.left = `${event.clientX - this.rangValue / 2}px`;
    };
    window.addEventListener('mousemove', (event) => {
      updateCursorPosition(event);
      if (hoverContainer.matches(':hover')) {
        customCursor.classList.add('zoom');
        $('#move').css({
          width: this.rangValue,
          height: this.rangValue,
          opacity: '1',
        });
        $('#custom-cursor').css({
          opacity: '1',
        });
      } else {
        customCursor.classList.remove('zoom');
        $('#move').css({
          opacity: '0',
        });
        $('#custom-cursor').css({
          opacity: '0',
        });
      }
    });
  },
  methods: {
    // 监听range事件
    handleInput(event) {
      this.rangValue = event.target.value;
    },
    // 点击选中高亮
    selectPicture(index, { img }) {
      this.resultGraphImg = img;
      this.imgCurrentActive = index;

      // 根据图片设置画板的宽度、高度
      var canvas = document.getElementById('canvas');
      // 画板大小
      canvas.width = 0;
      canvas.height = 0;
      this.isSample = 0;
      this.imageName = '';

      // this.$nextTick(() => {
      // 获取原始图片宽高
      var imga = new Image();
      imga.src = img;
      imga.onload = () => {
        // 原始高度
        this.widtha = imga.width;
        this.heighta = imga.height;
        this.canvas();
      };
      // })

      // 图片队列
      this.cleanImgList = [];
      this.cleanImgList.push({ img: img });
      // console.log(this.cleanImgList)
    },

    // 获取缩小图片的宽度
    getScaledSize() {
      // 缩小后高度
      this.imageWidth = this.$refs.image.offsetWidth;
      this.imageHeight = this.$refs.image.offsetHeight;

      // 根据图片设置画板的宽度、高度
      var canvas = document.getElementById('canvas');
      // 画板大小
      canvas.width = this.imageWidth;
      canvas.height = this.imageHeight;
    },
    // 上传文件发生改变时
    handleChange(file, fileList) {
      // return;
      this.fileList = fileList;
      // 加载时禁止点击切换
      this.prohibitClicking = true;
      this.imgCurrentActive = -1;
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';
      this.isSample = 1;
    },
    // 成功回调
    getResultImg(response, file, fileList) {
      // 根据图片设置画板的宽度、高度
      var canvas = document.getElementById('canvas');
      // 画板大小
      canvas.width = 0;
      canvas.height = 0;
      if (response.state === '0x0000') {
        setTimeout(() => {
          this.exampleDiagram = [];
          this.exampleDiagram = [
            {
              img: response.data,
            },
          ];
          this.imgCurrentActive = 0;
          this.resultGraphImg = response.data;

          // 成功图
          this.successImg = true;
          this.loadImg = false;
          this.errImg = false;
          // 加载时禁止点击切换
          this.prohibitClicking = true;
          this.imageName = '';
          // 获取原始图片宽高
          // this.$nextTick(() => {
          var imgb = new Image();
          imgb.src = response.data;
          imgb.onload = () => {
            // 原始高度
            this.widtha = imgb.width;
            // console.log(this.widtha)

            this.heighta = imgb.height;
            // console.log(this.heighta)

            this.canvas();
          };

          // 图片队列
          this.cleanImgList = [];
          this.cleanImgList.push({ img: response.data });
          // })
        }, 1000);
      } else if (response.state === '0x0008') {
        this.$message.error(response.message);
        this.$refs.navheader.logOn();
      } else {
        setTimeout(() => {
          // 加载时禁止点击切换
          this.prohibitClicking = true;
          // 成功图
          this.successImg = false;
          // 失败图
          this.errImg = true;
          this.errText = response.message;
          this.loadImg = false;
          // 图片队列
          this.cleanImgList = [];
        }, 1000);
      }
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },

    canvas() {
      var _this = this;
      var canvas = document.getElementById('canvas');
      // 设置背景图
      var context = canvas.getContext('2d');
      // range.oninput = function () {
      //     this.title = 'lineWidth: ' + this.value
      // }
      var Mouse = { x: 0, y: 0 };
      var lastMouse = { x: 0, y: 0 };
      var painting = false;

      // 获取缩小后的图片  大小
      this.getScaledSize();
      var widtha = this.widtha;
      var heighta = this.heighta;
      var imageWidth = this.imageWidth;
      var imageHeight = this.imageHeight;

      canvas.onmousemove = function (e) {
        // console.log("onmousemove")
        lastMouse.x = Mouse.x;
        lastMouse.y = Mouse.y;
        Mouse.x = e.pageX - this.offsetLeft;
        Mouse.y = e.pageY - this.offsetTop;

        // console.log(painting)
        if (painting) {
          // console.log("12")
          /*
          画笔参数：
              linewidth: 线宽
              lineJoin: 线条转角的样式, 'round': 转角是圆头
              lineCap: 线条端点的样式, 'round': 线的端点多出一个圆弧
              strokeStyle: 描边的样式, 'white': 设置描边为白色
          */
          // 设置画笔为圆形
          // context.lineCap = 'round'
          // context.lineJoin = "round";
          // console.log(range.value)

          // 线宽提示
          var range = document.getElementById('customRange1');
          // console.log(range.value)
          context.lineWidth = range.value == '0' ? '1' : range.value;
          context.lineJoin = 'round';
          context.lineCap = 'round';
          context.strokeStyle = 'white';

          // 开始绘画
          context.beginPath();

          context.moveTo(lastMouse.x, lastMouse.y);
          context.lineTo(Mouse.x, Mouse.y);
          context.closePath();
          context.stroke();
        }
      };

      canvas.onmouseup = function () {
        console.log('onmouseup');
        // 调用接口
        painting = false;
        // console.log(painting)
        // 原始图片
        let imgBase64 = '',
          imageUrl = '';
        if (
          _this.resultGraphImg.indexOf('data:') != -1 &&
          _this.resultGraphImg.indexOf('base64') != -1
        ) {
          imgBase64 = _this.resultGraphImg;
        } else {
          imageUrl = _this.resultGraphImg;
        }
        // console.log(imageUrl)
        // // 原始宽度、高度
        // console.log(widtha)
        // console.log(heighta)
        // // 缩小后的高度、宽度
        // console.log(imageWidth)
        // console.log(imageHeight)

        //将掩码图   等比例放大 放大跟原图一样大
        var canvas1 = document.getElementById('canvas1');
        const ctx = canvas1.getContext('2d');
        const img1 = new Image();
        img1.src = canvas.toDataURL('image/png');

        var multipleWidth = widtha / imageWidth;
        var multipleHeight = heighta / imageHeight;

        img1.onload = () => {
          // canvas1.width = imageWidth * multipleWidth;
          // canvas1.height = imageHeight * multipleHeight;
          canvas1.width = widtha;
          canvas1.height = heighta;
          ctx.drawImage(img1, 0, 0, canvas1.width, canvas1.height);
          const maskUrl = canvas1.toDataURL('image/png');

          // console.log(maskUrl);
          // 如果需要下载生成的图片，可以创建一个链接并模拟点击下载
          // const link = document.createElement('a');
          // link.download = 'new-image.png';
          // link.href = maskUrl;
          // link.click();

          logoSmear({
            // 原图
            imageUrl,
            imgBase64,
            // 掩码图
            mask: canvas1.toDataURL('image/png'),
            isSample: _this.isSample,
          }).then(({ data }) => {
            console.log(data.state);
            if (data.state === '0x0000') {
              _this.resultGraphImg = data.data.base64;
              _this.imageName = data.data.imageName || data.data.maskName;
              // 图片队列
              _this.cleanImgList.push({ img: data.data.base64 });
            } else if (data.state === '0x0008') {
              _this.$message({
                message: data.message,
                type: 'error',
              });
              _this.$refs.navheader.logOn();
            } else {
              _this.$message({
                message: data.message,
                type: 'error',
              });
            }
          });
        };
      };

      // 鼠标按下
      canvas.onmousedown = function () {
        painting = true;
        // var _this = this
        // _this.canvas()
        // console.log(painting)
      };

      // 清空画布  撤回操作
      var clean = document.getElementById('clean');
      clean.onclick = function () {
        if (_this.cleanImgList.length > 1) {
          // 删除最后一个元素
          _this.cleanImgList.splice(_this.cleanImgList.length - 1, 1);
          var cleanImgListIndex =
            _this.cleanImgList[_this.cleanImgList.length - 1].img;
          _this.resultGraphImg = cleanImgListIndex;
        }

        if (_this.cleanImgList.length < 2) {
          _this.imageName = '';
        }
      };
    },
    // 下载----------
    submitForm() {
      if (this.imageName) {
        const base64 = this.resultGraphImg; // 这里是base64字符串
        const blob = this.base64ToBlob(base64);
        this.downloadFile(blob, this.imageName.split('.')[0] + '.png');
      }
    },
    base64ToBlob(base64) {
      const parts = base64.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },

    downloadFile(file, filename) {
      const a = document.createElement('a');
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },
    // submitForm(){
    //         console.log(this.imageName)
    // const maskUrl = canvas1.toDataURL('image/png');
    // const link = document.createElement('a');
    // link.download = 'new-image.png';
    // link.href = maskUrl;
    // link.click();
    // }
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}

/* header */
.core-function,
.application-scene,
.similar-main,
.different-advantage {
  width: 1280px;
  margin: auto;
}

.application-scene_main,
.different-advantage_main {
  margin-top: 80px;
}
// banner
.header-custom {
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  background: #4900b6;
  left: 50%;
  transform: translate(-50%);
  img {
    position: absolute;
  }
  .banner-bg {
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .banner-auto {
    width: 15%;
    bottom: 21.5%;
    left: 6.5%;
  }
  .banner-custom {
    width: 13.4%;
    bottom: 45%;
    right: 6.5%;
  }
}
/* 核心 */
.core-function {
  padding: 80px 0 80px;
  .core-list {
    width: 100%;
    display: flex;
    column-gap: 24px;
    margin-top: -118px;
    img {
      width: 15.1%;
    }
  }
}
.core-function /deep/ .common-two-column_img {
  width: 32%;
  margin-top: 0;
  margin-right: 109px;
}

.core-function_main,
.application-scene_main,
.different-advantage_main {
  margin-top: 80px;
}

// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  /deep/ .common-text {
    img {
      vertical-align: middle;
      display: inline-block;
      margin: -2px 3px 0;
      width: 24px;
    }
  }
  .operationBackgroundColor {
    height: calc(100% - 62px);
  }
  .similarityGraph {
    width: 32.8%;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .logoImg {
    flex-shrink: 0;
    width: 73.3%;
    height: 100%;
    margin-left: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: fit-content;
    }
  }
  .successImgProduct {
    width: 100%;
  }
  .operationYellowRight {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    margin: 7px 0 0 24px;
  }
  .recognitionResult {
    margin: 0;
    font-size: 18px;
    color: #000108;
    span {
      color: #ff8645;
    }
  }
  .detectionButton {
    background: rgba(108, 56, 224, 0.1);
    color: rgba(108, 56, 224, 1);
  }
  .confidenceLevel {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
  }
  .similarityGraphMain {
    height: 100%;
    justify-content: space-between;
    row-gap: 0.8%;
    column-gap: 0.8%;
  }
  .similarityGraphImg img {
    border-radius: 8px;
    overflow: hidden;
  }
}
.similar-main-cont {
  display: flex;
  width: 100%;
  height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}

/* 差异优势 */
.different-advantage-wrap {
  background: linear-gradient(140deg, #503ad9 20%, #722ab8 145%);
}
.different-advantage {
  padding-top: 80px;
  padding-bottom: 100px;
}
.different-advantage /deep/ .common-two-column {
  column-gap: 69px;
}
.different-advantage /deep/ .common-two-column_img {
  width: 65.9%;
  margin-right: 0px;
  margin-top: -7px;
}

/* 应用场景 */
.application-scene {
  padding-top: 80px;
  padding-bottom: 135px;
}
.application-scene /deep/ .common-two-column {
  column-gap: 24px;
}
.application-scene /deep/ .common-two-column_img {
  width: 45%;
  margin-right: 52px;
  margin-top: -78px;
}

/* // 鼠标滑过事件 变换成圆圈 */
body:hover #custom-cursor {
  opacity: 1;
}

body:hover .logoImg {
  cursor: none;
}
#custom-cursor {
  border-radius: 50%;
  background-color: #fff;
  position: fixed;
  top: 30px;
  left: 30px;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}
#move {
  content: '';
  border-radius: 50%;
  border: 1px solid #6567e1;
  width: 60px;
  height: 60px;
  z-index: 999;
  opacity: 0;
}

.uploadDocument {
  font-size: 16px;
  font-family: PingFang-SC-Bold;
  margin-left: 15px;
  margin-right: 15px;
}

.supportType {
  color: #999999;
  font-size: 14px;
  font-family: PingFang-SC-Regular;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
}

.supportType span {
  color: #0042ff;
  text-decoration: underline;
  cursor: pointer;
}

.logoImg {
  width: 1050px;
  height: 693px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: auto;
}

.logoImgLoad {
  width: 1050px;
  height: 690px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.logoImgResult {
  position: absolute;
  z-index: 1;
  /* width: 100%; */
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
}

.paintingBrush {
  align-items: center;
  justify-content: center;
  // margin-top: 24px;
  cursor: pointer;
}

.paintingBrushSize {
  padding-top: 20px;
  padding-bottom: 21px;
  border-bottom: 1px solid #f0f2f5;
}

.penClear {
  width: 48px;
  height: 48px;
  background-color: rgba(108, 56, 224, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &.disable {
    background-color: #f2f2f7;
  }
}
.penClear img {
  width: 20px;
  height: 20px;
  display: none;
}
.penClear img[type='normal'] {
  display: block;
}
.penClear.disable img {
  display: none;
}
.penClear.disable img[type='disabled'] {
  display: block;
}

.penClear:hover img {
  display: none;
}
.penClear:hover img[type='hover'] {
  display: block;
}
.penClear:active img {
  display: none;
}
.penClear:active img[type='active'] {
  display: block;
}
.penClear:active img[type='hover'] {
  display: none;
}

.penSize {
}

.down_canvas {
  padding-top: 17px;
  display: flex;
  align-items: center;
  &.disable {
    color: #98989e;
  }
  img {
    height: 18px;
    margin-right: 9px;
  }
}

.logoUploadMain {
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 25px;
  display: flex;
}

.logoUpload {
  width: 158px;
  height: 157px;
}

.logoUpload .el-upload--picture-card {
  width: 158px;
  height: 157px;
  line-height: 157px;
}

.logoExample {
  display: flex;
  overflow-x: auto;
}

.logoExampleMain {
  flex-shrink: 0;
  width: 158px;
  height: 157px;
  line-height: 157px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-left: 20px;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
}

.logoExampleMain img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}

.prohibitClicking {
  pointer-events: none;
}

#canvas {
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disable {
  pointer-events: none;
  cursor: default;
}

/* 隐藏range控件默认样式 */
/* input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  outline: 0;
  background-color: transparent;
  width: 500px;
  background: #6567E1;
} */

/* 定义range控件轨道的样式 */
/* input[type="range"]::-webkit-slider-runnable-track {
  height: 4px;
  background: #6567E1;
  border-radius: 10px;
} */

/* 定义range控件容器的样式 */
/* input[type="range" i]::-webkit-slider-container {
  height: 20px;
  overflow: hidden;
} */

/* 定义range控件拇指的样式 */
/* input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #C9BBFF;
  border: 1px solid transparent;
  margin-top: -8px;
  border-image: linear-gradient(#6567E1, #6567E1) 0 fill / 8 20 8 0 / 0px 0px 0 2000px;
} */

input[type='range'] {
  position: relative;
  -webkit-appearance: none; /*清除系统默认样式*/
  width: 100%;
  height: 3px; /*横条的高度*/
  border-radius: 10px;
  background: -webkit-linear-gradient(
      rgba(108, 56, 224, 1),
      rgba(108, 56, 224, 1)
    )
    no-repeat;
  background-size: 0% 100%;
}
input[type='range']::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(108, 56, 224, 0.3);
  border-radius: 10px;
}
/*拖动块的样式*/
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /*清除系统默认样式*/
  height: 20px; /*拖动块高度*/
  width: 20px; /*拖动块宽度*/
  background: rgba(108, 56, 224, 1); /*拖动块背景*/
  border-radius: 50%; /*外观设置为圆形*/
  border: solid 1px rgba(108, 56, 224, 1); /*设置边框*/
}
.penSizePhone {
  display: flex;
  align-items: center;
  margin: 18px 0 19px;
  img {
    height: 15px;
    margin-right: 9px;
  }
  // display: block;
}
@media screen and (max-width: 1280px) {
  .similar-main-cont {
    flex-direction: column;
    height: auto;
    .logoImg {
      width: 100%;
      margin: 20px 0;
    }
  }
}
@media (max-width: 990px) {
  .logoUploadMain {
    margin-left: 15px;
    margin-bottom: 0px;
    margin-top: 25px;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
  }
  .logoExampleMain {
    width: 100px;
    height: 100px;
    /* line-height: 157px; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-left: 20px;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
  }
  .logoUpload .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  .logoUpload {
    width: 110px;
    height: 110px;
  }
  .paintingBrushSize {
  }
  .penClear {
    margin-top: 5px;
    margin-left: 0px;
  }
  .penSizePhone {
    // display: none;
  }
  .logoImg {
    width: 100%;
    height: 693px;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: auto;
  }
  .logoImgLoad {
    width: 100%;
    height: 500px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
}
/* 设置滚动条宽度 */
/* ::-webkit-scrollbar {
  width: 0px; 
} */
</style>
